import React, { useState, createContext, useCallback, useContext } from 'react';

import Modal from 'react-modal';

const ModalContext = createContext({});

const customStyles = {
  content: {
    top: '60%',
    left: '80%',
    right: 'auto',
    bottom: 'auto',
    padding: '0',
    border: '0',
    background: 'transparent',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const ModalProvider = ({ children }) => {
  const [component, setComponent] = useState(() => <div />);

  const renderComponent = useCallback(componentChildren => {
    setComponent(componentChildren);
  }, []);

  const [open, openModal] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        open,
        openModal,
        renderComponent,
      }}
    >
      {children}

      <Modal
        isOpen={open}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => openModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {component}
      </Modal>
    </ModalContext.Provider>
  );
};

export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal não pode ser usado sem o próprio contexto');
  }
  return context;
}
