import { createGlobalStyle } from 'styled-components';
import AvantRegular from '../assets/fonts/ITCAvant/AvantRegular.otf';
import AvantBook from '../assets/fonts/ITCAvant/AvantBook.otf';
import AvantBold from '../assets/fonts/ITCAvant/AvantBold.otf';

import TradeGothicBold from '../assets/fonts/TradeGothic/TradeGothicBold.otf';
import TradeGothicMedium from '../assets/fonts/TradeGothic/TradeGothicMedium.otf';

export default createGlobalStyle`

@font-face {
        font-family: 'AvantRegular';
        src: local('AvantRegular'), local('AvantRegular'),
        url(${AvantRegular}) format('truetype');
}
@font-face {
        font-family: 'AvantBook';
        src: local('AvantBook'), local('AvantBook'),
        url(${AvantBook}) format('truetype');
}
@font-face {
        font-family: 'AvantBold';
        src: local('AvantBold'), local('AvantBold'),
        url(${AvantBold}) format('truetype');
}
@font-face {
        font-family: 'TradeGothicMedium';
        src: local('TradeGothicMedium'), local('TradeGothicMedium'),
        url(${TradeGothicMedium}) format('truetype');
}
@font-face {
        font-family: 'TradeGothicBold';
        src: local('TradeGothicBold'), local('TradeGothicBold'),
        url(${TradeGothicBold}) format('truetype');
}

*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;

}

/* html, body, #root {
  height: 100%;
  // @media (max-width: 650px) {
  // //  min-height: 100%;
  //  height: auto;
  // }
} */
body {
  background: #fff;
  -webkit-font-smoothing: antialiased !important;
}

body,p,button, input{
  font: 16px 'AvantRegular', sans-serif;
  color: #383636;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

`;
