import React from 'react';
import AppProvider from './hooks/index';
import Routes from './routes';
import GlobalStyle from './styles/global';
import './config/ReactotronConfig';

function App() {
  window.onbeforeunload = function() {
    if (window.scrollTo) window.scrollTo(0, 0);
  };

  return (
    <>
      <GlobalStyle />
      <AppProvider>
        <Routes />
      </AppProvider>
    </>
  );
}

export default App;
