export default {
  title: 'redHeader',
  colors: {
    redNut: '#E33838',
    coolBlue: '#D1FAFA',
    cream: '#FEF2EB',
    darkGray: '#383636',
  },
  header: {
    color: 'rgba(227, 56, 56,)',
    colorMobile: '#E33838',
    textColor: '#fff',
    buttonBuyColor: '#383636',
  },
};
