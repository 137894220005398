import React, { useState, createContext, useContext, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import principal from '../styles/themes/principal';
import redHeader from '../styles/themes/redHeader';

const ThemeContext = createContext({});

export const ThemeProviderParent = ({ children }) => {
  const [theme, setTheme] = useState(redHeader);

  const toggleTheme = useCallback(title => {
    setTheme(title ? redHeader : principal);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider
        value={{
          theme,
          toggleTheme,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme não pode ser usado sem o próprio contexto');
  }
  return context;
}
