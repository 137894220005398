import React from 'react';

import { ModalProvider } from './modal';
import { ThemeProviderParent } from './theme';

const AppProviderContext = ({ children }) => (
  <ThemeProviderParent>
    <ModalProvider>{children}</ModalProvider>
  </ThemeProviderParent>
);

export default AppProviderContext;
