import React, { lazy, Suspense } from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

const Landing = lazy(() => import('./pages/Landing'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const PrivacyPortal = lazy(() => import('./pages/PrivacyPortal'));

export default function Routes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/" component={Landing} exact />
          <Route path="/privacy-policy" component={PrivacyPolicy} exact />
          <Route path="/privacy-portal" component={PrivacyPortal} exact />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
